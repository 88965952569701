import { format, addDays, parse } from 'date-fns'
import useCurrentData from './useCurrentData'
import useValidation from './useValidation'

export default () => {
  const { currentClub } = useCurrentData()
  const { isValidDate } = useValidation()

  const hhmm = date => {
    if (!isValidDate(date)) return ''

    return format(new Date(date), 'HH:mm')
  }

  const hhmmToDate = hhmmStr => {
    const openAt = hhmm(currentClub.value.openAt)
    const daysToAdd = hhmmStr > openAt ? 0 : 1

    return addDays(parse(hhmmStr, 'HH:mm', new Date()), daysToAdd)
  }

  const todayDateAndTime = () => {
    return format(new Date(), 'yyyy/MM/dd/ HH:mm')
  }

  const dateTime = date => {
    if (!isValidDate(date)) return ''

    return format(new Date(date), 'yyyy/MM/dd HH:mm')
  }

  const dateTimeForJsFriendly = date => {
    if (!isValidDate(date)) return ''

    return format(new Date(date), 'yyyy-MM-dd HH:mm')
  }

  return {
    hhmm,
    hhmmToDate,
    todayDateAndTime,
    dateTime,
    dateTimeForJsFriendly,
  }
}
