/* eslint-disable no-param-reassign */
import { ref } from '@vue/composition-api'

export default () => {
  const isLoading = ref(false)

  const initWith = async (promises = [], isLoadingRef = null) => {
    if (!isLoadingRef) isLoadingRef = isLoading

    isLoadingRef.value = true

    await Promise.all(promises)

    isLoadingRef.value = false
  }

  return {
    // data
    isLoading,

    // methods
    initWith,
  }
}
