import Vue from 'vue'
import VueRouter from 'vue-router'
import { find } from 'lodash'
import camelcaseKeys from 'camelcase-keys'
import { canNavigate } from '@/plugins/acl/routeProtection'
import UserApi from '@/api/waiter/User'
import admin from './admin'
import waiter from './waiter'
import cast from './cast'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      const currentClub = JSON.parse(localStorage.getItem('currentClub'))

      const userRole = currentUser && currentUser.role ? currentUser.role : null

      const routeNames = {
        admin: 'admin-home',
        waiter: 'waiter-halls',
        cast: 'cast-test',
      }
      const routeName = routeNames[userRole]

      if (routeName) return { name: routeName, params: { club_slug: currentClub.slug } }

      return { name: 'auth-login' }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...admin,
  ...waiter,
  ...cast,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

const getUsersOwnerships = async () => {
  let availableClubs = []

  const res = await UserApi.getUsersOwnerships()
  if (res?.data) availableClubs = [...res.data]

  return availableClubs
}

const updateCurrentClub = async id => {
  const currentClub = JSON.parse(localStorage.getItem('currentClub') || '{}')
  if (+id === +currentClub.id) return

  const res = await UserApi.updateUserCurrentClubId({ clubId: id })
  if (res?.data) localStorage.setItem('currentClub', JSON.stringify(camelcaseKeys(res.data)))
}

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = localStorage.getItem('currentUser')
    && localStorage.getItem('currentClub')
    && localStorage.getItem('userAbilities')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // 表示非表示の制御はcaslでやってるけど、アクセスはここでやってる
    return next('/')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  // club_slugがcurrentClubと違う場合はownershipsを取得してcurrentClubを更新する
  // 直リンクでアクセスした時などclub_slugとcurrentClubが異なる場合があるため
  // e.g.
  // 複数店舗経営のスーパーアドミンが店舗Aにログインしている状態で店舗BのURL(日報)を共有されたときとか
  const currentClub = JSON.parse(localStorage.getItem('currentClub') || '{}')
  const currentParamsClubSlug = to.params.club_slug
  if (currentClub.slug !== currentParamsClubSlug) {
    const availableClubs = await getUsersOwnerships()
    const clubId = find((availableClubs || []), { slug: currentParamsClubSlug })?.id

    if (clubId) {
      await updateCurrentClub(clubId)
    }
  }

  return next()
})

export default router
