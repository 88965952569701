const cast = [
  {
    path: '/:club_slug/cast',
    name: 'cast-test',
    component: () => import('@/views/cast/Test.vue'),
    meta: {
      layout: 'cast',
      resource: 'Cast',
    },
  },
]

export default cast
