import axiosIns from '@/plugins/axios'

export const signIn = async (email, password) => axiosIns.post(
  '/auth/sign_in',
  { email, password },
)

export const signOut = async () => axiosIns.delete(
  '/auth/sign_out',
)

export const clearAuthLocalStorage = () => {
  localStorage.removeItem('currentUser')
  localStorage.removeItem('currentClub')
  localStorage.removeItem('userAbilities')
}
