<template>
  <layout-admin :nav-menu-items="navMenuItems">
    <slot v-if="!isLoading" />

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Left Content: -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>

          <v-btn
            v-if="['staging', 'development'].includes(currentEnv)"
            color="error"
            :ripple="false"
            small
          >
            {{
              {
                staging: 'STG',
                development: 'DEV'
              }[currentEnv]
            }}
          </v-btn>
        </div>

        <!-- Right Content: -->
        <div class="d-flex align-center right-row">
          <clock class="mr-4" />
          <v-btn
            icon
            depressed
            :ripple="false"
            :loading="isLoading"
            @click="$router.go(0)"
          >
            <v-icon>
              {{ icons.mdiReload }}
            </v-icon>
          </v-btn>
          <app-bar-theme-switcher class="mx-4" />
          <cash-register-menu />
          <div
            v-show="$route.name === 'admin-home' && $vuetify.breakpoint.mdAndDown"
            class="mr-11"
          >
          </div>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <!-- TODO -->
    </template>
  </layout-admin>
</template>

<script>
import { reactive, provide } from '@vue/composition-api'
import { mdiMenu, mdiHeartOutline, mdiReload } from '@mdi/js'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import CashRegisterMenu from '@core/layouts/components/app-bar/CashRegisterMenu.vue'
import Clock from '@/views/components/util/Clock.vue'
import navMenuItems from '@/navigation/admin'
import LayoutAdmin from '@/@core/layouts/variants/LayoutAdmin.vue'
import ClubApi from '@/api/waiter/Club'
import useReceiptPrinterConnection from '@/views/composable/useReceiptPrinterConnection'
import useCompInit from '@/views/composable/useCompInit'

export default {
  components: {
    LayoutAdmin,
    AppBarThemeSwitcher,
    Clock,
    CashRegisterMenu,
  },
  setup() {
    const { isLoading, initWith } = useCompInit()
    const {
      isConnected,
      isConnecting,
      printer,
      checkConnection,
    } = useReceiptPrinterConnection()
    const currentClubMeta = reactive({
      remainingCashRegisterMoney: 0,
      todaySalesUnfixed: 0,
      todaySales: 0,
      today: new Date().toISOString().substr(0, 10),
    })

    const getClub = async () => {
      const res = await ClubApi.getClub()

      if (res?.data?.data) {
        const {
          remainingCashRegisterMoney,
          todaySalesUnfixed,
          todaySales,
          today,
        } = res.data.data.meta

        currentClubMeta.remainingCashRegisterMoney = remainingCashRegisterMoney
        currentClubMeta.todaySalesUnfixed = todaySalesUnfixed
        currentClubMeta.todaySales = todaySales
        currentClubMeta.today = today
      }
    }

    checkConnection()
    initWith([
      getClub(),
    ])

    provide('currentClubMeta', currentClubMeta)
    provide('isReceiptPrinterConnected', isConnected)
    provide('isReceiptPrinterConnecting', isConnecting)
    provide('printer', printer)
    provide('getClub', getClub)

    return {
      navMenuItems,
      currentClubMeta,
      isLoading,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }
  }
}
</style>
