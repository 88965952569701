/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by1 a service worker. For more details, visit https://goo.gl/AFskqB')
    },
    registered(registration) {
      console.log('Service worker has been registered. ver 1.2')
      /* eslint-disable */
      setInterval(() => {
        console.log("poling....")

        try {
          registration.update();
        } catch (error) {
          console.error(error)
        }
      }, 1000 * 60); // e.g. 1 minitue checks
      /* eslint-enable */
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      )
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
