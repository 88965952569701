import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getCashRegisterTransactions({ date = null, interval = null, endDate = null }) {
    return axiosIns.get(
      '/waiter/cash_register_transactions',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  createCashRegisterTransaction({ transactionType, amount, note }) {
    const formData = serialize(snakecaseKeys({
      cashRegisterTransaction: { transactionType, amount, note },
    }))

    return axiosIns.post('/waiter/cash_register_transactions', formData)
  },
}
