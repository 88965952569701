<template>
  <div v-if="isReady">
    <span v-if="!$vuetify.breakpoint.xsOnly">
      {{ year }}/{{ mon }}/{{ date }}({{ day }})
    </span>
    {{ hour }}:{{ min }}
  </div>
</template>

<script>
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'

export default {
  setup() {
    const isReady = ref(false)
    const year = ref(null)
    const mon = ref(null)
    const date = ref(null)
    const day = ref(null)
    const hour = ref(null)
    const min = ref(null)

    const days = {
      0: '日',
      1: '月',
      2: '火',
      3: '水',
      4: '木',
      5: '金',
      6: '土',
    }

    const setCurrentDate = () => {
      const currentDate = new Date()
      year.value = currentDate.getFullYear()
      mon.value = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      date.value = (currentDate.getDate()).toString().padStart(2, '0')
      day.value = days[currentDate.getDay()]
      hour.value = (currentDate.getHours()).toString().padStart(2, '0')
      min.value = (currentDate.getMinutes()).toString().padStart(2, '0')

      isReady.value = true
    }

    const clock = setInterval(() => setCurrentDate(), 1000)

    onUnmounted(() => {
      clearInterval(clock)
      isReady.value = false
    })

    return {
      isReady,
      year,
      mon,
      date,
      day,
      hour,
      min,
    }
  },
}
</script>
