<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <div>
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            :block="block"
            :ripple="false"
            color="secondary"
            :disabled="!isReceiptPrinterConnected || isPrinting"
            :loading="isPrinting"
            class="w-full"
            @click="executePrint"
          >
            <v-icon left>
              {{ icons.mdiPrinterPos }}
            </v-icon>
            {{ subject }}を印刷
          </v-btn>
        </span>

        <slot name="append" />
      </div>
    </template>

    <span v-if="!isReceiptPrinterConnected">
      機能を有効にするためはプリンタとの接続が必要です
    </span>
  </v-tooltip>
</template>

<script>
import { ref, inject } from '@vue/composition-api'
import { mdiPrinterPos } from '@mdi/js'

export default {
  props: {
    subject: {
      type: String,
      required: true,
      default: '?',
    },
    print: {
      type: Function,
      required: true,
      default: () => {},
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isReceiptPrinterConnected = inject('isReceiptPrinterConnected', false)

    const isPrinting = ref(false)

    const executePrint = () => {
      isPrinting.value = true

      props.print()

      // NOTE: 本当は印刷終わってからfalseに戻したいけどePosが非同期なので、とりあえずsetTimeoutで印刷中なことを表現する
      setTimeout(() => {
        isPrinting.value = false
      }, 2500)
    }

    return {
      // data
      isReceiptPrinterConnected,
      isPrinting,

      // methods
      executePrint,

      icons: {
        mdiPrinterPos,
      },
    }
  },
}
</script>
