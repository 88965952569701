import {
  mdiDotsSquare,
  mdiAccountClockOutline,
  mdiScriptTextOutline,
  mdiDatabaseOutline,
  mdiCogs,
  mdiFileChartOutline,
} from '@mdi/js'
import { format } from 'date-fns'
import useCurrentData from '@/views/composable/useCurrentData'

const { currentClub } = useCurrentData()

export default [
  {
    title: 'ホール',
    icon: mdiDotsSquare,
    to: {
      name: 'waiter-halls',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Waiter',
  },
  {
    title: 'オーダー',
    icon: mdiScriptTextOutline,
    to: {
      name: 'waiter-orders',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Waiter',
  },
  {
    title: '勤怠/待機',
    icon: mdiAccountClockOutline,
    to: {
      name: 'waiter-timecards',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Waiter',
  },
  {
    title: '日報',
    icon: mdiFileChartOutline,
    to: {
      name: 'waiter-daily-reports',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Admin',
  },
  {
    title: 'データ',
    icon: mdiDatabaseOutline,
    to: {
      name: 'waiter-data',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Waiter',
  },
  {
    title: '設定',
    icon: mdiCogs,
    children: [
      {
        title: 'ホール',
        to: {
          name: 'waiter-settings-halls',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: 'メニュー',
        to: {
          name: 'waiter-settings-vendibles',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: '商品タグ',
        to: {
          name: 'waiter-settings-vendible-tags',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: `${currentClub.value?.referralAlias || 'リファラル'}`,
        to: {
          name: 'waiter-settings-referrals',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: '顧客タグ',
        to: {
          name: 'waiter-settings-customer-tags',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: '売上計上トリガー',
        to: {
          name: 'waiter-settings-vending-contribution-triggers',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: 'ユーザー',
        to: {
          name: 'waiter-settings-users',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: 'シフト',
        to: {
          name: 'waiter-settings-work-schedules',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
      {
        title: '諸入費ラベル',
        to: {
          name: 'waiter-settings-book-tags',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Admin',
      },
      {
        title: `${currentClub.value?.name || '店舗'}`,
        to: {
          name: 'waiter-settings-club',
          params: {
            club_slug: currentClub.value?.slug,
          },
        },
        resource: 'Waiter',
      },
    ],
  },
]
