export default () => {
  const isValidId = id => {
    return Number(id) > 0
  }

  const isValidDate = date => {
    return !(new Date(date).toString() === 'Invalid Date')
  }

  // 文字列で"attributes.タイム型"になっているか確認する
  const isValidDateTimeAttributes = key => {
    return [
      'attributes.startAt',
      'attributes.leaveAt',
      'attributes.breakTimeStartAt',
      'attributes.breakTimeEndAt',
      'attributes.createdAt',
      'attributes.receipt.data.attributes.createdAt',
    ].includes(key)
  }

  return {
    isValidId,
    isValidDate,
    isValidDateTimeAttributes,
  }
}
