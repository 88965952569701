import Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import App from './App.vue'
import './registerServiceWorker'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

const toastOptions = {
  position: 'top-center',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
}
Vue.use(Toast, toastOptions)

Vue.mixin({
  computed: {
    currentUser() {
      return JSON.parse(localStorage.getItem('currentUser') || '{}')
    },
    currentClub() {
      return JSON.parse(localStorage.getItem('currentClub') || '{}')
    },
    currentEnv() {
      return process.env.NODE_ENV
    },
  },
})

Vue.use(Toast)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://115c17a7724d452bae8f0fb7b9a26d5a@o1329206.ingest.sentry.io/6591106',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['yona.yonarezi.com', 'yonarezi.com/api/v1', 'yonarezi.com/api/v2'],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
