import {
  mdiHomeAnalytics,
  mdiWrenchOutline,
  mdiCoachLamp,
  mdiCctv,
} from '@mdi/js'
import useCurrentData from '@/views/composable/useCurrentData'
// import useQueryString from '@/views/composable/useQueryString'
import waiterSidebarContent from '../waiter/sidebarContent'

const { currentClub } = useCurrentData()
// const { route } = useQueryString()
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
})

export default [
  {
    title: 'HOME',
    icon: mdiHomeAnalytics,
    to: {
      name: 'admin-home',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'Admin',
  },
  {
    subheader: 'POS',
    resource: 'Admin',
    action: 'manage',
  },
  ...waiterSidebarContent,
  {
    subheader: 'HR',
    resource: 'Admin',
    action: 'manage',
  },
  {
    title: 'マネジメント',
    icon: mdiCoachLamp,
    resource: 'Admin',
    action: 'manage',
    to: {
      name: 'admin-hr-earning-summaries',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
  },
  {
    title: 'システム構築',
    icon: mdiWrenchOutline,
    resource: 'Admin',
    action: 'manage',
    children: [
      {
        title: '基本給',
        resource: 'Admin',
        action: 'manage',
        children: [
          {
            title: '時給',
            to: {
              name: 'admin-wage-hourly-wage',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
        ],
      },
      {
        title: 'バック',
        resource: 'Admin',
        action: 'manage',
        children: [
          {
            title: 'システム',
            to: {
              name: 'admin-rebate-system-systems',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: 'ユニット',
            to: {
              name: 'admin-rebate-system-units',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '要素',
            to: {
              name: 'admin-rebate-system-rebates',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
        ],
      },
      {
        title: '変動給',
        resource: 'Admin',
        action: 'manage',
        children: [
          {
            title: 'システム',
            to: {
              name: 'admin-pay-system-systems',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: 'ユニット',
            to: {
              name: 'admin-pay-system-units',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
        ],
      },
      {
        title: '条件',
        resource: 'Admin',
        action: 'manage',
        children: [
          {
            title: 'ポイント',
            to: {
              name: 'admin-pay-condition-vending-point-conditions',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '売上',
            to: {
              name: 'admin-pay-condition-sales-conditions',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '給率',
            to: {
              name: 'admin-pay-condition-contribution-ratio-conditions',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '販売',
            to: {
              name: 'admin-pay-condition-vending-size-conditions',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '紹介',
            to: {
              name: 'admin-pay-condition-referring-size-conditions',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
          {
            title: '卓フィルタ',
            to: {
              name: 'admin-table-filters',
              params: {
                club_slug: currentClub.value?.slug,
              },
            },
            resource: 'Admin',
          },
        ],
      },
    ],
  },
  {
    subheader: '管理',
    resource: 'audit:receipts',
    action: 'read',
  },
  {
    title: 'ログ監視β',
    icon: mdiCctv,
    to: {
      name: 'admin-audits',
      params: {
        club_slug: currentClub.value?.slug,
      },
    },
    resource: 'audit:receipts',
    action: 'read',
  },
]
