import { ref } from '@vue/composition-api'
import { PrinterConnection } from '@yonarezi/yonarezi3.epos-printer'
import useCurrentData from '@/views/composable/useCurrentData'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'

export default () => {
  const { currentClub } = useCurrentData()

  const isConnected = ref(null)
  const isConnecting = ref(null)
  const printer = ref(null)

  const checkConnection = async (printIpAddress = null) => {
    isConnecting.value = true

    let ipAddress = printIpAddress
    if (!ipAddress) {
      ipAddress = currentClub.value.printIpAddress
    }
    ipAddress = removePortFromIpAddress(ipAddress)

    try {
      const printerConnection = new PrinterConnection({ ipAddress })
      printer.value = await printerConnection.connect()
      isConnected.value = !!printer.value.ePosDev.isConnected()
    } catch (error) {
      console.error(error)
    } finally {
      isConnecting.value = false
    }
  }

  return {
    // data
    isConnected,
    isConnecting,
    printer,

    // methods
    checkConnection,
  }
}
