import qs from 'qs'
import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  /**
  * @param {string} roleType if you set role, get users only with the role。
  *
  */
  getUsers(roleType) {
    const userParams = {
      role: roleType,
    }

    const config = {
      method: 'Get',
      params: userParams,
      paramsSerializer(params) {
        return qs.stringify(params)
      },
    }

    const response = axiosIns.get('/waiter/users', config)

    return response
  },
  createUser(addTargetUser) {
    const formData = serialize(snakecaseKeys({
      user: {
        name: addTargetUser.value.name,
        role: addTargetUser.value.role,
      },
    }))
    // NOTE: HACK: 直接いれないと送信されなかった
    formData.append('user[profile_image]', addTargetUser.value.profileImage)

    const response = axiosIns.post('/waiter/users', formData)

    return response
  },
  updateUser({
    id,
    name,
    profileImage,
  }) {
    const formData = serialize(snakecaseKeys({
      user: {
        name,
      },
    }))
    // NOTE: HACK: 直接いれないと送信されなかった
    formData.append('user[profile_image]', profileImage)
    const response = axiosIns.put(`/waiter/users/${id}`, formData)

    return response
  },
  updateSubscriptionPayload({
    id,
    subscriptionPayload,
  }) {
    const formData = serialize(snakecaseKeys({
      user: {
        subscriptionPayload,
      },
    }))
    const response = axiosIns.put(`/waiter/users/${id}`, formData)

    return response
  },
  deleteUser({ id }) {
    const response = axiosIns.delete(`/waiter/users/${id}`)

    return response
  },
  updateUserCurrentClubId({ clubId }) {
    const formData = serialize(snakecaseKeys({
      user: {
        clubId,
      },
    }))
    const response = axiosIns.put('/waiter/users/update_current_club', formData)

    return response
  },
  getUsersOwnerships() {
    const response = axiosIns.get('/waiter/users/ownerships')

    return response
  },
}
